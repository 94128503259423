<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import errorHandler from '../../../helpers/errorHandler';
import userService from '../../../helpers/userService';
import Axios from 'axios'

/**
 * Starter component
 */
export default {
  page: {
    title: "Reports",
  },
  head() {
    return {
      title: `Reports`,
    };
  },
  data() {
    return {
      recordId: null,
      title: "Reports",
      items: [
        {
          text: "Manage",
          href: "/home",
        },
        {
          text: "Reports",
          href: "/reports"
        },
      ],
      stage: '',
      reportType: '',
      submitted: false,
      stageError: null,
      typeError: null,
      types:[
        {
          text: 'Students',
          value: 'students'
        },
        {
          text: 'Centers',
          value: 'centers'
        },
      ],
      totalRows:0,
      perPage: 20,
      currentPage: 1,
      pageOptions: [10, 20, 30],
      filter: "",
      sortBy: "total",
      sortDesc: true,
      filterOn: ["name","student_code","stage"],
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
          selected: true
        },
        {
          key: "student_code",
          label: "Student ID",
          sortable: true,
          selected: true
        },
        {
          key: "stage",
          label: "Stage",
          sortable: true,
          selected: true
        },
        {
          key: "chances",
          label: "Chances",
          sortable: true,
          selected: true
        },
        {
          key: "elements",
          label: "Elements",
          sortable: true,
          selected: true
        },
        {
          key: "total",
          label: "Total",
          sortable: true,
          selected: true
        },
      ]
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  methods:{
    async downloadFantasyReprot(){
      await Axios.get(`/periodic_table/fantasy_report_export`, { responseType: 'blob',headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`} })
        .then(response => {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'Fantasy Report.xlsx'
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(error =>{
          errorHandler.methods.errorValue("No data to download or You can't download this file now!!")
        })
    },
    async tryToLink() {
      this.submitted = true;
      if (!this.validate()) {
        return;
      } else {
        if(this.reportType == 'students'){
          await Axios.get(`/admin/yearly_attendance?stage=${this.stage}`, { responseType: 'blob',headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`} })
          .then(response => {
            const blob = new Blob([response.data])
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'Student Report.xlsx'
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(error =>{
            if(error.response.data.detail["error message"]){
              errorHandler.methods.error(error)
            }else{
              errorHandler.methods.errorMessage(error)
            }
          })
        }else{
          await Axios.get(`/attendance/download_attendance?year=${this.stage}`, { responseType: 'blob',headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`} })
          .then(response => {
            const blob = new Blob([response.data])
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'Center Report.xlsx'
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(error =>{
            if(error.response.data.detail["error message"]){
              errorHandler.methods.error(error)
            }else{
              errorHandler.methods.errorMessage(error)
            }
          })
        }
      }
    },
    clearSelectError2(){
      this.stageError = null;
    },
    clearSelectError3(){
      this.typeError = null;
    },
    validate(){
      if(this.stage){
        this.stageError = true;
      }else{
        this.stageError = false;
      }
      if(this.reportType){
        this.typeError = true;
      }else{
        this.typeError = false;
      }
      return this.stageError && this.typeError;
    },
    viewProfile(record,index){
      this.$router.push('/students/' + record.student_id + '/details')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  computed:{
    report(){
      return this.$store.getters['fantasy/report'];
    },
    tableFields() {
      return this.fields.filter(f => f.selected);
    },
    rows() {
      return this.report.length;
    },
    stages(){
      return this.$store.getters['option/years'];
    },
  },
  async created(){
    await this.$store.dispatch('option/getYearOption').then().catch(err => console.log(err));
    await this.$store.dispatch('fantasy/getFantasyReprot').then().catch(err => console.log(err));
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div>
      <div class="row justify-content-center" >
        <div class="col-md-12">
          <div class="card">
            <div class="card-body p-4">
                <h2 class="card-title">
                    Reprots 
                </h2>
              <form action="#" @submit.prevent="tryToLink">
                <div class="row justify-content-center" >
                    <div class="col-md-4" >
                      <b-form-group label="Stage :" label-for="input-C" >
                        <b-form-select  v-model="stage" :options="this.stages" :state="stageError" @change="clearSelectError2"></b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-md-4" >
                      <b-form-group label="Report type :" label-for="input-C" >
                        <b-form-select  v-model="reportType" :options="this.types" :state="typeError" @change="clearSelectError3"></b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-12 text-center">
                        <b-button variant="success" pill type="submit" style="width: 15rem;">
                          Report
                        </b-button>
                    </div>
                  </div>
                </form>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Display&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;Records
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                  >
                      <b-button variant="success" class="btn-sm mr-2" @click="downloadFantasyReprot()">
                        <i class="mdi mdi-chart-line"></i>
                        Export Fantasy
                      </b-button>
                    <label class="d-inline-flex align-items-center mt-2">
                      Search:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                    <b-dropdown
                      id="dropdown-offset"
                      offset="250"
                      variant="link"
                      class="btn-icon-only"
                    >
                      <template #button-content>
                        <i class="fas fa-th-large iconGray"></i>
                      </template>
                      <b-dropdown-form>
                        <b-form-checkbox
                          v-for="(field, index) in fields"
                          :key="index"
                          class="mb-3"
                          v-model="field.selected"
                          :disabled="field.disabled"
                          >{{ field.label }}</b-form-checkbox
                        >
                      </b-dropdown-form>
                    </b-dropdown>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <b-table
                table-class="table table-centered w-100 table-hover"
                thead-tr-class="bg-light"
                tbody-tr-class="clickableRow"
                :items="report"
                :fields="tableFields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                @row-clicked="viewProfile"
              >
              <template v-slot:cell(phone_numbers)="data">
                {{ data.item.phone_numbers[0].phone_number }}
              </template>
              <template v-slot:cell(emails)="data">
                {{ data.item.emails[0].email }}
              </template>
            <template v-slot:cell(action)="data">
                  <b-dropdown
                    class="btn-icon-onl"
                    right
                    toggle-class="arrow-none btn-light btn-sm"
                  >
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal iconGray"></i>
                    </template>

                    <b-dropdown-item
                      :to="'/centers/' + data.item.id + '/edit'"
                    >
                      <i
                        class="mdi mdi-pencil mr-2 text-muted font-18 vertical-middle iconGray"
                      ></i
                      >Edit
                    </b-dropdown-item>
                    <b-dropdown-item
                  v-on:click="showDeleteModal(data.item.id)"
                    ><i
                      class="mdi mdi-delete mr-2 text-muted font-18 vertical-middle iconGray"
                    ></i
                    >Delete
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
              <div class="row">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="(filter == '' )?rows:totalRows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
